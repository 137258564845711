<script setup>
const props = defineProps({
  open: Boolean,
  t: { type: [String, Number], default: null }
})
const styleCssVar = computed(() => {
  return props.t !== null ? { '--collapse-time': `${props.t}` } : {}
})
</script>

<template>
  <div class="collapsible-box" :class="{ open: props.open }" :style="styleCssVar">
    <div>
      <slot />
    </div>
  </div>
</template>

<style lang='scss'>
:root {
  --collapse-time: 0.3s;
}
.collapsible-box {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows var(--collapse-time);

  & > * {
    overflow: hidden;
  }

  &.open {
    grid-template-rows: 1fr;
  }
}
</style>
